/** GLOBAL **/
const body = document.querySelector('body');
const headerWrapper = document.querySelector('.header-wrapper');

import Swiper, { Manipulation, Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination, Manipulation]);

let swiperTouchStartX;

const productsSliderSwiper = new Swiper('.products-slider', {
  // Optional parameters
  slidesPerView: 1.25,
  spaceBetween: 20,
  centeredSlides: true,
  loop: true,
  keyboard: {
    enabled: true,
  },
  //breakpoints
  breakpoints: {
    '@0.75': {
      slidesPerView: 2,
    },
    '@1.25': {
      slidesPerView: 3,
    },
    '@1.50': {
      slidesPerView: 4,
    },
    '@2.00': {
      slidesPerView: 5,
    },
    '@2.5': {
      slidesPerView: 6,
    },
  },
  on: {
    init(swiper) {
      const totalSlidesLen = swiper.slides.length;
      swiper.el.querySelector('.swiper-button-prev').addEventListener('click', () => {
        if (swiper.isBeginning) {
          swiper.slideTo(totalSlidesLen - 1);
        } else {
          swiper.slideTo(swiper.realIndex - 1);
        }
      });
      
      swiper.el.querySelector('.swiper-button-next').addEventListener('click', () => {
        if (swiper.isEnd) {
          swiper.slideTo(0);
        } else {
          swiper.slideTo(swiper.realIndex + 1);
        }
      });
    },
    
    touchStart(swiper, e) {
      if (e.type === 'touchstart') {
        swiperTouchStartX = e.touches[0].clientX;
      } else {
        swiperTouchStartX = e.clientX;
      }
    },
    
    touchEnd(swiper, e) {
      const tolerance = 150;
      const totalSlidesLen = swiper.slides.length;
      const diff = (() => {
        if (e.type === 'touchend') {
          return e.changedTouches[0].clientX - swiperTouchStartX;
        } else {
          return e.clientX - swiperTouchStartX;
        }
      })();
      if (swiper.isBeginning && diff >= tolerance) {
        swiper.slideTo(totalSlidesLen - 1);
      } else if (swiper.isEnd && diff <= -tolerance) {
        setTimeout(() => {
          swiper.slideTo(0);
        }, 1);
      }
    },
  },
  
});

/** Carousel **/
const carouselSwiper = new Swiper('.carousel', {
  // Optional parameters
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  centeredSlides: true,
  keyboard: {
    enabled: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/** Product page swiper **/
const productSwiper = new Swiper('.product-swiper', {
  // Optional parameters
  slidesPerView: 1,
  spaceBetween: 20,
  centeredSlides: true,
  loop: true,
  pagination: {
    el: ".swiper-pagination"
  },
  keyboard: {
    enabled: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/** HEADER - LOGO SIZE ON SCROLL **/
const headerLogo = document.querySelector('.header-logo-wrapper');
let ticking = false;

function resizeLogo() {
  if (document.body.scrolltop > 50 || document.documentElement.scrollTop > 50) {
    headerLogo.classList.add('w-[145px]');
  } else {
    headerLogo.classList.remove('w-[145px]');
  }
}

window.onscroll = function() {
  ticking = true;
}

setInterval(() => {
  if (ticking) {
    ticking = false;
    resizeLogo();
  }
},300);

/**  NAVIGATION **/
const nav = headerWrapper.querySelector('.nav');
const toggleMenuBtn = headerWrapper.querySelector('.toggle-menu-button');
const navListWrappers = nav.querySelectorAll('.nav__list-wrapper');
const closeMenuIcon = headerWrapper.querySelector('.close-menu')
const burgerIcon = headerWrapper.querySelector('.burger');

  toggleMenuBtn.addEventListener('click', () => {
    toggleMenu();
  })

function toggleMenu() {
  body.classList.toggle('overflow-hidden');
  nav.classList.toggle('hidden');
  burgerIcon.classList.toggle('hidden');
  closeMenuIcon.classList.toggle('hidden');
}

navListWrappers.forEach(wrapper => {
    wrapper.querySelector('.nav__list-heading').addEventListener('click', (e) => {
      wrapper.querySelector('.nav__list').classList.toggle('hidden');
    })
});

/** ACCORDION **/
const accordions = document.querySelectorAll('.accordion');

accordions.forEach(accordion => {
  const items = accordion.querySelectorAll('.accordion-item, .accordion-item--invisible');
  items.forEach(item => {
    item.querySelector('.accordion-item__title').addEventListener('click', () => {
      if (item.classList.contains('accordion-item--invisible')) {
        item.querySelector('.accordion-item__content').classList.toggle('invisible');
      } else {
        item.classList.toggle('shadow-md');
        item.classList.toggle('md:shadow-lg');
        item.querySelector('.accordion-item__content').classList.toggle('hidden');
      }
      item.querySelector('.accordion-item__title').classList.toggle('after:rotate-180')
    })
  })
})

/** HEADER ACCORDION **/
const headerAccordions = document.querySelectorAll('.header-accordion');

headerAccordions.forEach(accordion => {
  const items = accordion.querySelectorAll('.header-accordion-item, .header-accordion-item--invisible');
  items.forEach(item => {
    item.querySelector('.header-accordion-item__title').addEventListener('click', () => {
      if (item.classList.contains('header-accordion-item--invisible')) {
        item.querySelector('.header-accordion-item__content').classList.toggle('invisible');
      } else {
        item.querySelector('.header-accordion-item__content').classList.toggle('hidden');
      }
      item.querySelector('.header-accordion-item__title').classList.toggle('after:rotate-180')
    })
  })
})

/** VIDEO MODAL **/
const modalVideos = document.querySelectorAll('.modal-videos-js');
modalVideos.forEach(modalVideos => {
  modalVideos.querySelector('.close-modal-button').addEventListener('click', () => {
    modalVideos.querySelector('.video-modal').classList.toggle('hidden');
    modalVideos.querySelector('.video-wrapper').classList.toggle('translate-y-1/3');
    modalVideos.querySelector('.video-wrapper').classList.toggle('opacity-0');
    body.classList.toggle('overflow-hidden');
  })
  modalVideos.querySelectorAll('.videoModal__item').forEach(item => {
    item.addEventListener('click', (e) => {
      body.classList.toggle('overflow-hidden');
      item.closest('.modal-videos-js').querySelector('.video-modal').classList.toggle('hidden');
      item.closest('.modal-videos-js').querySelector('.video-wrapper').classList.toggle('translate-y-1/3');
      item.closest('.modal-videos-js').querySelector('.video-wrapper').classList.toggle('opacity-0');
      item.closest('.modal-videos-js').querySelector('.modal-video').setAttribute('src', item.dataset.videoUrl)
    })
  })
})

/** RETAILER FILTER **/
const retailer = document.querySelector('.retailer-js');
if (typeof retailer !== undefined && retailer !== null) {
  const dropdown = document.getElementById('retailer-select');
  const allRetailers = retailer.querySelectorAll('.retailer-item');

    if (allRetailers.length > 0) {
        updateList(allRetailers);
    }

    dropdown.addEventListener('change', () => {
        updateList(allRetailers);
    })

    function updateList(cardList) {
        cardList.forEach(card => {
            if (card.dataset.location.includes(dropdown.value)) {
                card.classList.remove('hidden');
            } else {
                card.classList.add('hidden');
            }
        })
    }
}

/**  PRODUCTS FILTER **/
const productFilters = document.querySelectorAll('.products-filter-js');
productFilters.forEach(filter => {
  const resetBtn = filter.querySelector('.products-filter-btn');
  // console.log(resetBtn.innerHTML);
  let activeBtn = resetBtn;
  activeBtn.classList.remove('btn-white-alt');
  activeBtn.classList.add('btn-blue');
  const productItems = filter.querySelectorAll('.products-filter-item');

  let hasSlider = false;
  if (filter.classList.contains('products-swiper-slider')) {
    hasSlider = true;
  }

  filter.querySelectorAll('.products-filter-btn').forEach(btn => {
    // Set active category from url location search
    if(window.location.search) {
      const params = new URL(location.href).searchParams;
      const category = params.get('kategori');
      const filterBtn = btn.dataset.categoryBtn;

      if (filterBtn === category) {
        console.log(btn)
        console.log(activeBtn.innerHTML)
        activeBtn.classList.remove('btn-blue');
        activeBtn.classList.add('btn-white-alt');
        btn.classList.remove('btn-white-alt');
        btn.classList.add('btn-blue');
        activeBtn = btn;
        filterProducts(btn.dataset.categoryBtn, resetBtn, productItems, hasSlider);
        if (hasSlider) {
          updateSwiper();
        }
      }
    }
    
    btn.addEventListener('click', () => {
      if (btn === activeBtn) {
        return;
      }
      activeBtn.classList.remove('btn-blue');
      activeBtn.classList.add('btn-white-alt');
      btn.classList.remove('btn-white-alt');
      btn.classList.add('btn-blue');
      activeBtn = btn;
      filterProducts(btn.dataset.categoryBtn, resetBtn, productItems, hasSlider);
      if (hasSlider) {
        updateSwiper();
      }
    })
  })
})

function filterProducts(category, resetBtn, items, hasSlider) {
  if (category === resetBtn.dataset.categoryBtn) {
    showAllItems(items, hasSlider);
    return;
  }
  
  hideAllItems(items, hasSlider);
  
  let c = 0;
  items.forEach((item, i) => {
    if (item.dataset.category === category) {
      item.classList.remove('hidden');
      if (hasSlider) {
        productsSliderSwiper.addSlide(c, item);
      }
      c++;
    }
  })
}

function showAllItems(items, hasSlider) {
  items.forEach((item, i) => {
    if (item.classList.contains('hidden')) {
      item.classList.remove('hidden');
      if (hasSlider) {
        productsSliderSwiper.addSlide(i, item);
      }
    }
  })
}

function hideAllItems(items, hasSlider) {
  items.forEach((item, i) => {
    if (!item.classList.contains('hidden')) {
      item.classList.add('hidden');
    }
  })
  if (hasSlider) {
    productsSliderSwiper.removeAllSlides();
  }
}

function updateSwiper() {
  productsSliderSwiper.update();
}

const tabPanes = document.querySelectorAll('.nav-link');
const tabContent = document.querySelectorAll('.tab-pane');

tabPanes.forEach(pane => {
  pane.addEventListener('click',(e)=>{
    e.preventDefault();
    tabPanes.forEach((p,i)=>{
      p.classList.remove('active');
    })
    let newActiveElementId = pane.getAttribute('data-bs-target');
    pane.classList.add('active');
    tabContent.forEach(c=>{
      c.classList.remove('show')
      if(newActiveElementId.indexOf(c.id) !== -1)
        c.classList.add('show');
    })
  })
})


// For events page
const itemsPerPage = 6;
let currentPage = 1;
let filteredItems = [];
let selectedLocation;
let selectedSubject;

document.addEventListener("DOMContentLoaded", function() {
  if (!document.getElementById('events-card-container')) {
    return;
  }

  let events = window.events;
  const subjectSelect = document.getElementById('subject-select');
  const locationSelect = document.getElementById('location-select');
  const loadMoreButton = document.getElementById('load-more');
  const searchInput = document.getElementById("event-search");

  searchInput.addEventListener("input", function() {
    const searchTerm = searchInput.value.toLowerCase();
    const filteredItems = events.filter(event => {
      const title = event.Title.toLowerCase();
      const subjects = event.Subjects.map(subject => subject.toLowerCase());
      const location = event.Location.toLowerCase();
      return title.includes(searchTerm) || location.includes(searchTerm) || subjects.some(subject => subject.includes(searchTerm));
    });
    document.getElementById('events-card-container').innerHTML = '';
    displayItems(filteredItems, 1);
  });

  if (subjectSelect) {
    subjectSelect.addEventListener('change', function() {
      selectedSubject = this.value;
      filterItems(events);
    });
  }

  if (locationSelect) {
    locationSelect.addEventListener('change', function() {
      selectedLocation = this.value;
      filterItems(events);
    });
  }

  filterItems(events);

  loadMoreButton.addEventListener('click', function(e) {
    e.preventDefault();
    currentPage++;
    displayItems(filteredItems, currentPage);
  });

});


function displayItems(items, page) {
  const eventCardsContainer = document.getElementById('events-card-container');
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;
  const paginatedEvents = items.slice(startIndex, endIndex);

  paginatedEvents.forEach(event => {
    eventCardsContainer.insertAdjacentHTML('beforeend', createEventCardHTML(event));
  });

  const loadMoreButton = document.getElementById('load-more');
  loadMoreButton.style.display = endIndex >= items.length ? 'none' : 'block';
}

function filterItems(events) {
  filteredItems = events;

  if (selectedSubject !== 'all' && selectedSubject !== undefined && selectedSubject != null) {
    filteredItems = filteredItems.filter(event => event.Subjects.includes(selectedSubject));
  }

  if (selectedLocation !== 'all' && selectedLocation !== undefined && selectedLocation != null) {
    filteredItems = filteredItems.filter(event => event.Location.includes(selectedLocation));
  }

  currentPage = 1;
  document.getElementById('events-card-container').innerHTML = '';
  displayItems(filteredItems, currentPage);
}

function createEventCardHTML(event) {
  const eventStatus = event.Ended
      ? '<div class="event-card-meta__item">\n' +
      'Avsluttet\n' +
      '</div>'
      : `<div class="event-card-meta__item">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0C13.9699 0 18 4.02935 18 9C18 13.9707 13.9707 18 9 18C4.03011 18 0 13.9707 0 9C0 4.02935 4.03011 0 9 0ZM8.23174 5.08017V9.00057C8.23174 9.28478 8.38569 9.53272 8.61512 9.66594L11.9294 11.5806C12.2972 11.7937 12.768 11.6679 12.9811 11.3001C13.1943 10.9322 13.0685 10.4615 12.7006 10.2483L9.76813 8.55491V5.07996C9.76813 4.65512 9.42471 4.3117 8.99987 4.3117C8.57503 4.3117 8.23161 4.65586 8.23161 5.07996L8.23174 5.08017ZM14.2735 3.72721C11.3611 0.814813 6.63978 0.814813 3.72738 3.72721C0.814983 6.63885 0.814983 11.3609 3.72738 14.2733C6.63903 17.1857 11.3611 17.1857 14.2735 14.2733C17.1859 11.3609 17.1859 6.63961 14.2735 3.72721Z" fill="white"/>
            </svg>
            ${event.Time}
            </div>`;

  return `
        <div class="flex flex-col" data-location="${event.Location}" data-subjects="${event.Subjects.join(',')}">
            <div class="relative">
                <div class="tag absolute top-0 right-0">${event.Subject}</div> 

                <div>
                    <img class="w-full h-full object-cover" src="${event.Image}" alt="${event.ImageAlt}">
                </div>
    
                <div class="event-card-meta ${event.EventEnded} absolute bottom-0 inset-x-0 flex flex-wrap gap-3 justify-between py-3 px-5 bg-aass-dark-blue bg-opacity-80 text-white">
                    <div class="event-card-meta__item">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.3912 1.76037H14.7254V0.946356C14.7254 0.416245 14.3092 0 13.7791 0C13.249 0 12.8327 0.416245 12.8327 0.946356V1.76037H11.5271V0.946356C11.5271 0.416245 11.1108 0 10.5807 0C10.0506 0 9.63434 0.416245 9.63434 0.946356V1.76037H8.32867V0.946356C8.32867 0.416245 7.91243 0 7.38232 0C6.85221 0 6.43596 0.416245 6.43596 0.946356V1.76037H5.13029L5.12955 0.946356C5.12955 0.416245 4.71331 0 4.1832 0C3.65309 0 3.23684 0.416245 3.23684 0.946356V1.76037H1.5711C0.700152 1.76037 0 2.46053 0 3.33148V16.2212C0 17.0922 0.700152 17.7923 1.5711 17.7923H16.4289C17.2998 17.7923 18 17.0922 18 16.2212V3.33148C17.9623 2.46053 17.2621 1.76037 16.3912 1.76037ZM16.3912 15.8234C16.3912 16.0319 16.2211 16.2212 15.9934 16.2212L1.93112 16.2205C1.72262 16.2205 1.53334 16.0504 1.53334 15.8227V5.62134C1.53334 5.41283 1.70339 5.22356 1.93112 5.22356H15.9942C16.2027 5.22356 16.3919 5.39361 16.3919 5.62134L16.3912 15.8234Z" fill="white"/>
                            <path d="M5.8865 11.6025H4.20229C3.91838 11.6025 3.69141 11.8295 3.69141 12.1134V13.5707C3.69141 13.8546 3.91838 14.0815 4.20229 14.0815H5.8865C6.17041 14.0815 6.39738 13.8546 6.39738 13.5707V12.1134C6.39738 11.8295 6.17041 11.6025 5.8865 11.6025Z" fill="white"/>
                            <path d="M9.80441 11.6025H8.12019C7.83629 11.6025 7.60931 11.8295 7.60931 12.1134V13.5707C7.60931 13.8546 7.83629 14.0815 8.12019 14.0815H9.80441C10.0883 14.0815 10.3153 13.8546 10.3153 13.5707L10.316 12.1134C10.316 11.8295 10.0883 11.6025 9.80441 11.6025Z" fill="white"/>
                            <path d="M13.7415 11.6025H12.0573C11.7734 11.6025 11.5464 11.8295 11.5464 12.1134V13.5707C11.5464 13.8546 11.7734 14.0815 12.0573 14.0815H13.7415C14.0254 14.0815 14.2524 13.8546 14.2524 13.5707V12.1134C14.2524 11.8295 14.0254 11.6025 13.7415 11.6025Z" fill="white"/>
                            <path d="M5.8865 7.5708H4.20229C3.91838 7.5708 3.69141 7.79777 3.69141 8.08168V9.53892C3.69141 9.82283 3.91838 10.0498 4.20229 10.0498H5.8865C6.17041 10.0498 6.39738 9.82283 6.39738 9.53892V8.08168C6.39738 7.79777 6.17041 7.5708 5.8865 7.5708Z" fill="white"/>
                            <path d="M9.80441 7.5708H8.12019C7.83629 7.5708 7.60931 7.79777 7.60931 8.08168V9.53892C7.60931 9.82283 7.83629 10.0498 8.12019 10.0498H9.80441C10.0883 10.0498 10.3153 9.82283 10.3153 9.53892V8.08168C10.316 7.79777 10.0883 7.5708 9.80441 7.5708Z" fill="white"/>
                            <path d="M13.7415 7.5708H12.0573C11.7734 7.5708 11.5464 7.79777 11.5464 8.08168V9.53892C11.5464 9.82283 11.7734 10.0498 12.0573 10.0498H13.7415C14.0254 10.0498 14.2524 9.82283 14.2524 9.53892V8.08168C14.2524 7.79777 14.0254 7.5708 13.7415 7.5708Z" fill="white"/>
                        </svg>
                        ${event.Date}

                    </div>
                    ${eventStatus}
                    <div class="event-card-meta__item event-card-meta__location">
                        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 18C8.08608 15.4384 12 9.84462 12 6.27739C12 2.81032 9.31386 0 6 0C2.68614 0 0 2.81032 0 6.27739C0 9.84462 3.9141 15.4377 6 18ZM8.75826 6.27739C8.75826 7.87107 7.52395 9.16317 6 9.16317C4.47675 9.16317 3.24174 7.8718 3.24174 6.27739C3.24174 4.68372 4.47605 3.39084 6 3.39084C7.52325 3.39084 8.75826 4.68296 8.75826 6.27739Z" fill="white"/>
                        </svg>
                        ${event.Location}
                    </div>
                </div>
            </div>
            <div class="flex flex-col grow ${event.Ended ? "bg-aass-light-gold" : "bg-aass-light-blue"} text-aass-dark-blue p-5 md:p-6">
                <h3 class="normal-case mb-4">${event.Title}</h3>
                <a href="${event.Url}" class="text-right uppercase no-underline mt-auto text-sm">Les mer</a>
            </div>
        </div>
    `;
}